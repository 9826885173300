import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button/Button';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import CardBase from 'components/organisms/CardBase/CardBase';

const useStyles = makeStyles((theme) => ({
  cardBase: {
    background: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      background: `url(/assets/logo_only.svg) no-repeat 50% 10% ${theme.palette.primary.dark}`,
    },
  },
  textWhite: {
    color: 'white',
  },
  sectionHeader: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
}));

const Subscription = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <CardBase
        variant="outlined"
        liftUp
        className={classes.cardBase}
        align="left"
        data-aos="fade-up"
      >
        <SectionHeader
          title={
            <span className={classes.textWhite}>
              Sign up for a 1 month trial of VRGS.
            </span>
          }
          subtitle={
            <span className={classes.textWhite}>
              No obligation trial. Try VRGS on your own data, or use one of our
              demo datasets.
            </span>
          }
          fadeUp
          align="left"
          ctaGroup={[
            <Button variant="contained" size="large" href="/get-trial">
              Download now
            </Button>,
          ]}
          disableGutter
          className={classes.sectionHeader}
        />
      </CardBase>
    </div>
  );
};

Subscription.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Subscription;
