import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import Button from '@mui/material/Button/Button';
import { StaticImage } from 'gatsby-plugin-image';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
    },
  },
}));

const Hero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
                Gain maximum value from your
                <br />
                <Typography component="span" variant="inherit" color="primary">
                  digital outcrop models.
                </Typography>
              </span>
            }
            subtitle="Major corporations, consultants, researchers and students use VRGS to get the most from their digital geological models. Save yourself time and money with the best outcrop modelling software available."
            ctaGroup={[
              <Button
                variant="contained"
                color="primary"
                size="large"
                href="/get-trial"
              >
                30 Day Trial
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h2"
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <div
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <StaticImage
              src="images/desktop1.jpg"
              alt="Get the most from your digital geological models with VRGS from VRGeoscience Limited"
              placeholder="blurred"
              className={classes.image}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
