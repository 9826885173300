import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import { StaticImage } from 'gatsby-plugin-image';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
    },
  },
  lastGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '40%',
    },
  },
}));

const Customization = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        label="Customisation"
        title="Customise your product"
        subtitle="Do you need a bespoke solution, or require and new feature just for you? VRGeoscience can provide bespoke customisations and features to suit your needs."
        align="center"
        ctaGroup={[
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="/contact-us"
          >
            Contact Us
          </Button>,
        ]}
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center">
            <StaticImage
              src="images/fractures-and-zones.jpg"
              alt="Fracture and zone mapping in VRGS"
              placeholder="blurred"
              className={classes.image}
              data-aos="fade-up"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center" className={classes.lastGrid}>
            <StaticImage
              src="images/field-tour.jpg"
              alt="Digital outcrop models for virtual field trips"
              placeholder="blurred"
              className={classes.image}
              data-aos="fade-up"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Customization.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Customization;
