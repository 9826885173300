import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors, Divider } from '@mui/material';
import Section from 'components/organisms/Section/Section';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';

import {
  Customization,
  Download,
  Hero,
  Hub,
  Reviews,
  Support,
  VideoSection,
} from './components';

import { reviews, support } from './data';

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: '50%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const Overview = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.shape}>
        <Section className={classes.pagePaddingTop}>
          <VideoSection
            videoSrc="https://www.youtube.com/embed/pj4XzIiRgD4"
            videoTitle="Digital Outcrop Models and Virtual Geology"
            videoSubtitle="Natural resource management (for mining, CO2 storage, aquifers etc.) often relies on an understanding of the geology underground. As we usually cannot see this geology we look to outcrop analogues to give us this understanding. Digital outcrop models help us to quantify geological heterogeneity and to reduce uncertainty by making accurate measurments and interpretations."
            buttonName="30 Day Trial"
            buttonLink="/get-trial"
          />
        </Section>
        <Section className={classes.pagePaddingTop}>
          <Hero />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Hub />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Reviews data={reviews} />
        </Section>{' '}
      </div>
      <Section narrow>
        <Support data={support} />
      </Section>
      <SectionAlternate>
        <Customization />
      </SectionAlternate>

      <Section>
        <Download data={[]} />
      </Section>
      <Divider />
    </div>
  );
};

export default Overview;
