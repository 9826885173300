/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import DigitalOutcrops from 'views/DigitalOutcrops';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import SEO from '../src/components/SEO';
export const Head = () => (
  <SEO description="Powerful tools for interpretation of digital outcrop models in 3D." />
);

const DigitalOutcropsPage = () => {
  return <WithLayout component={DigitalOutcrops} layout={Main} />;
};

export default DigitalOutcropsPage;
