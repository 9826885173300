export const reviews = [
  {
    logo: '/assets/logo_only.svg',
    title: 'VRGS',
    feedback:
      'Advanced digital outcrop interpretation and visualisation software with 16 years of academic research and development behind it.',
  },
  {
    logo: '/assets/logo_only.svg',
    title: 'GeoTour3D',
    feedback:
      'Coming soon: GeoTour3D is a web based viewer and storage soltion for 3D digital geological models and virtual fieldtrips. Host and share your data in the cloud.',
  },
];

export const support = {
  items: ['Helpful', 'Experienced', 'Expert'],
  team: [
    {
      authorPhoto: {
        src: '/assets/logo_only.svg',
        srcSet: '/assets/logo_only.svg 2x',
      },
      authorName: 'Kate Segelson',
    },
  ],
};
